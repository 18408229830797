<template>

  <t-layout>
    <t-aside>
      <div>
        <div class="logo-title">
          循仁科技
        </div>
        <t-menu
            theme="light"
            :default-value="currentRoute"
            @change="handleMenuChange"
        >
<!--          <t-menu-item value="/home">-->

<!--            风险病人1-->
<!--          </t-menu-item>-->
<!--          <t-menu-item value="/dashboard">-->
<!--            风险病人报告1-->
<!--          </t-menu-item>-->
<!--          <t-menu-item value="/analytics">-->

<!--            投诉处理1-->
<!--          </t-menu-item>-->
<!--          <t-menu-item value="/settings">-->

<!--            反馈报告1-->
<!--          </t-menu-item>-->
          <t-menu-item value="/riskPatientList">

            风险病人
          </t-menu-item>
          <t-menu-item value="/ComplaintsList">

            投诉列表
          </t-menu-item>
          <t-menu-item value="/RiskPatientReportList">

            反馈报告列表
          </t-menu-item>
          <t-menu-item value="/CaseList">

            病例列表
          </t-menu-item>

        </t-menu>
      </div>

    </t-aside>
    <t-layout>
      <t-header style="text-align: right;line-height: 50px;">
        <t-dropdown :options="options" trigger="click" @click="clickHandler">
          <t-space>

            <t-button variant="text">
              <template #icon><UserCircleIcon /></template>

              {{username}}
              <template #suffix> <t-icon name="chevron-down" size="16" /></template>
            </t-button>
          </t-space>
        </t-dropdown>
      </t-header>
      <t-content style="padding: 20px">
        <router-view :key="$route.fullPath" />
      </t-content>
      <t-footer></t-footer>
    </t-layout>
  </t-layout>







</template>

<script setup>
/* eslint-disable vue/multi-word-component-names */
import { computed } from 'vue' // 添加 computed 导入
import { useRouter, useRoute } from 'vue-router'
import {UserCircleIcon} from "tdesign-icons-vue-next";

const router = useRouter()
const route = useRoute()

const currentRoute = computed(() => route.path)

const handleMenuChange = (value) => {
  // 确保使用 name 进行导航
  router.push({
    path: value
  })
}

const options = [
  { content: '退出登录', value: 1 },
];
const username = localStorage.getItem('username');

const clickHandler = (data) => {
  console.log("clickHandler",data)
  localStorage.removeItem('authToken');
  router.push('/login');
};


</script>

<style scoped>
.main-layout {
  height: 100vh;
  flex-direction: row;
}
.logo-title{
  font-size: 20px;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid var(--td-component-stroke);
  border-right: 1px solid var(--td-component-stroke);
  padding: 17px 0;
  font-weight: 600;
}

.main-header {
  background: #fff;
  border-bottom: 1px solid var(--td-component-stroke);
  padding: 0 24px;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.main-content {
  padding: 24px;
  background: var(--td-bg-color-page);
  overflow: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
