// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
// import RiskPatientList  from "./components/RiskPatientList.vue";
// import UserLogin  from "./components/Login.vue";
// import RiskPatientReport from "@/components/RiskPatientReport.vue";
// import FeedbackReport from "@/components/FeedbackReport.vue";
// import yoshiindex  from "./components/yoshiindex.vue";
// import HandledComplaints from "@/components/HandledComplaints.vue";
import MainLayout from '@/layout/MainLayout.vue'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/Login.vue'),
    },
    {
        path: '/',
        component: MainLayout,
        redirect: '/riskPatientList',
        children: [
            // {
            //     path: '/home',
            //     name: '风险病人1',
            //     component: () => import('@/components/RiskPatientList.vue')
            // },
            // {
            //     path: '/dashboard',
            //     name: '风险病人报告1',
            //     component: () => import('@/components/RiskPatientReport.vue')
            // },
            // {
            //     path: '/analytics',
            //     name: 'Analytics',
            //     component: () => import('@/components/HandledComplaints.vue')
            // },
            // {
            //     path: '/settings',
            //     name: 'Settings',
            //     component: () => import('@/components/FeedbackReport.vue')
            // },
            {
                path: '/riskPatientList',
                name: '风险病人',
                component: () => import('@/views/RiskPatientList.vue')
            },
            {
                path: '/ComplaintsList',
                name: '投诉列表',
                component: () => import('@/views/ComplaintsList.vue')
            },
            {
                path: '/RiskPatientReportList',
                name: '反馈报告列表',
                component: () => import('@/views/RiskPatientReportList.vue')
            },
            {
                path: '/CaseList',
                name: '病例列表',
                component: () => import('@/views/CaseList.vue')
            },
        ]
    }
]




//
//
// const routes = [
//     {
//         path: '/',
//         component: UserLogin,
//     },
//     {
//         path: '/riskPatient',
//         component: RiskPatientList,
//     },
//     {
//         path: '/yoshiindex',
//         component: yoshiindex,
//     },
//     {
//         path:'/riskPatientReport',
//         component: RiskPatientReport,
//     },
//     {
//         path:'/feedbackReport',
//         component: FeedbackReport,
//     },
//     {
//         path:'/handledComplaints',
//         component: HandledComplaints,
//     }
// ];


const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
