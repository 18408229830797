// src/main.js
import {createApp} from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import TDesign from 'tdesign-vue-next'
import 'tdesign-vue-next/es/style/index.css'

const app = createApp(App);
app.use(router);
app.use(TDesign)
app.mount('#app');