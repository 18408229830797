<!-- src/App.vue -->
<template>
  <div id="app">

    <router-view />


<!--    <nav v-if="isLoggedIn" class="navbar">-->
<!--      <div class="nav-links">-->
<!--        <router-link class="nav-link" to="/riskPatient">风险病人</router-link>-->
<!--        <router-link class="nav-link" to="/riskPatientReport">风险病人报告</router-link>-->
<!--        <router-link class="nav-link" to="/handledComplaints">已处理投诉</router-link>-->
<!--        <router-link class="nav-link" to="/feedbackReport">反馈报告</router-link>-->
<!--        <button class="link-button" @click="handleLogout">退出登录</button>-->
<!--      </div>-->
<!--    </nav>-->
<!--    &lt;!&ndash; Listen for the 'login' event on the router-view &ndash;&gt;-->
<!--    <router-view @login="handleLogin"></router-view>-->
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false, // Initially set to false
    };
  },
  created() {
    // Check if the user is logged in when the app is created
    const token = localStorage.getItem('authToken'); // Example: Check for a token in localStorage
    if (token) {
      this.isLoggedIn = true;

    }else{
      this.$router.push('/login');
    }
  },
  methods: {
    handleLogin() {
      this.isLoggedIn = true;
    },
    handleLogout() {
      this.isLoggedIn = false;
      localStorage.removeItem('authToken'); // Clear the token
      this.$router.push('/'); // Redirect to login page after logout
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  background-color: transparent; /* Transparent background */
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0; /* Light border for separation */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.nav-links {
  display: flex;
  gap: 15px; /* Space between links */
}

.nav-link {
  color: #007bff; /* Blue text color */
  text-decoration: none;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue background on hover */
  color: #0056b3; /* Darker blue text on hover */
}

.nav-link.active {
  color: #003d7a; /* Darker blue for the active link */
}

.link-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
}

.link-button:hover {
  color: #0056b3;
}
</style>
